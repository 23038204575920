
export const Section = ({ title, children, isMobile }) => (
  <section style={{
    flex: '1',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: 'white',
    color: '#0A3D62',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: isMobile ? '20px' : '0'
  }}>
    <h2 style={{ fontFamily: 'Roboto, Arial, sans-serif', fontSize: '1.75rem', color: '#0A3D62' }}>{title}</h2>
    {children}
  </section>
);
