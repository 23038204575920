import React, { useState } from 'react';

// New ExpandableBox component
export const ExpandableBox = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ borderRadius: '8px', backgroundColor: '#F9FAFB', marginBottom: '20px' }}>
      <h3 style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '1.5rem',  }} onClick={toggleOpen}>
        {title}
        <span style={{ marginLeft: 'auto', transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
          {isOpen ? '▲' : '▼'}
        </span>
      </h3>
      {isOpen && <div>{children}</div>}
    </div>
  );
}