import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@mui/material'

// Function to categorize based on T-score for patients aged 50 or older
function categorizeBasedOnTScore(tScore) {
  if (tScore >= -1.0) {
    return "Normal";
  } else if (tScore > -2.5 && tScore < -1.0) {
    return "Low Bone Mass";
  } else if (tScore <= -2.5) {
    return "Osteoporosis";
  }
  return null;
}

// Function to categorize based on Z-score for patients under 50 years of age
function categorizeBasedOnZScore(zScore) {
  if (zScore > -2.0) {
    return "Within Expected Range for Age";
  } else if (zScore <= -2.0) {
    return "Below Expected Range for Age";
  }
  return null;
}

// Function to find the lowest T-score or Z-score and their corresponding sites
function findLowestScores(patientAge, boneDensityData) {
  let lowestTScore = null;
  let lowestZScore = null;
  let lowestTSite = null;
  let lowestZSite = null;

  boneDensityData.forEach(entry => {
    const site = entry.SITE.toLowerCase();

    // Exclude sites that should not be used for diagnostic categorization
    if (site.includes("trochanter") || site.includes("ward") || site.includes("female reference")) {
      return;
    }

    const tScore = entry["T-score"] !== null ? parseFloat(entry["T-score"]) : null;
    const zScore = entry["Z-score"] !== null ? parseFloat(entry["Z-score"]) : null;

    if (patientAge >= 50) {
      if (tScore !== null && (lowestTScore === null || tScore < lowestTScore)) {
        lowestTScore = tScore;
        lowestTSite = site;
      }
    } else {
      if (zScore !== null && (lowestZScore === null || zScore < lowestZScore)) {
        lowestZScore = zScore;
        lowestZSite = site;
      }
    }
  });

  return { lowestTScore, lowestZScore, lowestTSite, lowestZSite };
}

// Function to determine the diagnostic category based on the lowest T-score or Z-score
function getDiagnosticCategory(patientAge, boneDensityData, explanation) {
  const { lowestTScore, lowestZScore, lowestTSite, lowestZSite } = findLowestScores(patientAge, boneDensityData);

  if (patientAge >= 50 && lowestTScore !== null) {
    const category = categorizeBasedOnTScore(lowestTScore);
    explanation.push(`${category} determined based on the lowest T-score of ${lowestTScore} at ${lowestTSite}`);
    return category;
  } else if (patientAge < 50 && lowestZScore !== null) {
    const category = categorizeBasedOnZScore(lowestZScore);
    explanation.push(`${category} determined based on the lowest Z-score of ${lowestZScore} at ${lowestZSite}`);
    return category;
  }

  return null;
}

function getCarocTable(sex) {
  const carocTableWomen = [
    [50, -2.5, -3.8],
    [55, -2.5, -3.8],
    [60, -2.3, -3.7],
    [65, -1.9, -3.5],
    [70, -1.7, -3.2],
    [75, -1.2, -2.9],
    [80, -0.5, -2.6],
    [85, 0.1, -2.2],
  ];

  const carocTableMen = [
    [50, -2.5, -3.9],
    [55, -2.5, -3.9],
    [60, -2.5, -3.7],
    [65, -2.4, -3.7],
    [70, -2.3, -3.7],
    [75, -2.3, -3.8],
    [80, -2.1, -3.8],
    [85, -2.0, -3.8],
  ];

  return sex.toLowerCase() === "female" ? carocTableWomen : carocTableMen;
}

function getRelevantTScore(bmdValues, sex) {
  const preferredSites = sex.toLowerCase() === "female"
    ? ["LEFT FEMORAL NECK", "RIGHT FEMORAL NECK"]
    : ["LEFT FEMORAL NECK (FEMALE REFERENCE)", "RIGHT FEMORAL NECK (FEMALE REFERENCE)"];

  for (const site of preferredSites) {
    for (const entry of bmdValues) {
      if (entry.SITE === site && entry["T-score"] !== null) {
        return parseFloat(entry["T-score"]);
      }
    }
  }
  return null;
}

function getLumbarScore(bmdValues) {
  const lumbarEntry = bmdValues.find(entry => entry.SITE.includes("LUMBAR SPINE"));
  return lumbarEntry ? parseFloat(lumbarEntry["T-score"]) : null;
}

function getRiskThresholds(carocTable, age, explanation) {
  let ageGroup1, ageGroup2;
  if (age >= 85) {
    ageGroup1 = carocTable[carocTable.length - 2];
    ageGroup2 = carocTable[carocTable.length - 1];
    explanation.push(`Patient Age: ${age}. Using risk thresholds for age group ${ageGroup1[0]}-${ageGroup2[0]}.`);
  } else {
    for (let i = 0; i < carocTable.length - 1; i++) {
      if (carocTable[i][0] <= age && age < carocTable[i + 1][0]) {
        ageGroup1 = carocTable[i];
        ageGroup2 = carocTable[i + 1];
        explanation.push(`Patient Age: ${age}. Interpolating risk thresholds for age group ${ageGroup1[0]}-${ageGroup2[0]}.`);
        break;
      }
    }
  }

  const [age1, low1, high1] = ageGroup1;
  const [age2, low2, high2] = ageGroup2;
  const lowRiskThreshold = interpolate(age, age1, age2, low1, low2);
  const highRiskThreshold = interpolate(age, age1, age2, high1, high2);

  explanation.push(`Low risk threshold: ${lowRiskThreshold.toFixed(2)}, High risk threshold: ${highRiskThreshold.toFixed(2)}.`);
  return [lowRiskThreshold, highRiskThreshold];
}

function interpolate(x, x0, x1, y0, y1) {
  return y0 + (y1 - y0) * ((x - x0) / (x1 - x0));
}

function returnSummaryComponents(diagnosticCategory, fractureRiskCategory, bmdValues, institutionName, parsedObservations, explanation, patientAge, studyType) {
  const components = {};

  const cannotBeCompared = parsedObservations.cannot_be_compared || false;
  const boneActiveDrugTherapy = parsedObservations.bone_active_drug_therapy || false;

  const diagnostic = mapCategory(diagnosticCategory);
  const risk = stripRisk(fractureRiskCategory);

  // Overall statement
  if (risk === "Fracture risk cannot be stated in patients less than 50 years of age.") {
    components.overallStatement = `This patient has ${diagnostic}. ${risk}`;
  } else if (risk === "Undefined") {
    components.overallStatement = `This patient has ${diagnostic}. Fracture risk cannot be determined.`;
  } else {
    components.overallStatement = `This patient has ${diagnostic} with ${risk} fracture risk.`;
  }

  explanation.push(components.overallStatement);

  // Recommendations based on risk category
  const recommendations = {
    LOW: "Low risk patients are unlikely to benefit from pharmacologic therapy.",
    MODERATE: "In patients with moderate fracture risk, it would be appropriate to consider a lateral x-ray of the thoracic and lumbar spine from T4-L4 to assess for possible compression fractures. The presence of a compression fracture of more than 25% would place the patient into the HIGH RISK category for future fractures and could change management strategies.",
    Undefined: "CAROC fracture risk is not possible when the femoral neck and spine are not available or when the femoral neck is not available while the spine is available but has a T-score >-2.5. If there has been a fragility fracture of the vertebrae (at least 25% of the vertebral body height) or proximal femur, or more than 1 fragility fracture, the fracture risk would be high. Additionally, if the patient has been on 7.5 mg or more of prednisone (or prednisone equivalent of other glucocorticoid) daily for over 90 days in the past 12 months and has had a fragility fracture, the fracture risk would also be high.",
  };

  components.recommendations = recommendations[risk] || "";

  if (risk === "MODERATE" || risk === "HIGH") {
    if (!boneActiveDrugTherapy) {
      const additionalRecommendation = {
        MODERATE: "Consider pharmacologic therapy. Effective therapies are available in the form of bisphosphonates.",
        HIGH: "Pharmacologic therapy recommended. Effective therapies are available in the form of bisphosphonates.",
      };
      if (components.recommendations !== ""){
        components.recommendations += "\n\n"
      }
      components.recommendations += `${additionalRecommendation[risk] || ""}`;
    } else {
      if (components.recommendations !== ""){
        components.recommendations += "\n\n"
      }
      components.recommendations += "Fracture risk may be lower than calculated if bone active drug therapy is effective.";
    }
  }

  if (risk === "Fracture risk cannot be stated in patients less than 50 years of age.") {
    if (diagnosticCategory === "Below expected range for age") {
      if (components.recommendations) {
        components.recommendations += "\n\n";
      }
      components.recommendations += "Evaluation for secondary causes of reduced bone density is warranted.";
    }
  }

  if (components.recommendations !== ""){
    components.recommendations += "\n\n"
  }
  components.recommendations += "Non-pharmacological and lifestyle measures are applicable to all patients. All patients should ensure an adequate intake of dietary calcium (1200 mg/d) and vitamin D (800-2000 IU daily).";

  // Change statement
  if (cannotBeCompared) {
    components.changeStatement = getCannotBeComparedStatement(parsedObservations);
  } else {
    const changeValues = calculateChangeValues(bmdValues, institutionName, parsedObservations);
    components.changeStatement = getChangeStatement(changeValues);
    if (components.changeStatement !== ""){
      if (components["recommendations"]){
        components["recommendations"] += "\n\n"
      }
      const lscInfo = `LSC (least significant change) at ${institutionName}:
      Lumbar Spine - 0.033 gm/cm²
      Total Hip - 0.017 gm/cm
      Forearm - 0.016 gm/cm²
      Total Body - 0.015 gm/cm²
      `;
      components["recommendations"] += lscInfo;
    }
  }

  // Follow-up recommendation
  components.followUpStatement = getFollowUpStatement(fractureRiskCategory, diagnosticCategory, patientAge, studyType);

  return components;
}

// Function to map diagnostic categories
function mapCategory(diagnosticCategory) {
  const categoryMap = {
    "Normal": "NORMAL bone mass",
    "Low Bone Mass": "LOW bone mass",
    "Osteoporosis": "OSTEOPOROSIS",
    "Within expected range for age": "bone mass WITHIN expected range for age",
    "Below expected range for age": "bone mass BELOW expected range for age",
  };
  return categoryMap[diagnosticCategory] || diagnosticCategory;
}

// Function to strip risk categories
function stripRisk(riskCategory) {
  const riskMap = {
    "Low, less than 10%": "LOW",
    "Moderate, 10-20%": "MODERATE",
    "High, greater than 20%": "HIGH",
  };
  return riskMap[riskCategory] || riskCategory;
}

// Function to get a statement when results cannot be compared
function getCannotBeComparedStatement(parsedObservations) {
  const statements = [];

  if (parsedObservations.is_weight_change_gt_10_percent) {
    statements.push(
      "There has been a >10% interval change in the patient's body weight as compared to previous. " +
      "Significant changes in body weight (>10%) can interfere with precision of comparison between studies. " +
      "Current results cannot be accurately compared with previous."
    );
  }

  if (parsedObservations.different_exam_location) {
    statements.push(
      "Direct comparison to the prior examination cannot be performed as it was performed on a different machine " +
      "and any comparison would not be statistically valid.\n\n" +
      "This examination can serve as a baseline for future follow-up examinations, however."
    );
  }

  return statements.length > 0 ? statements.join("\n\n") : null;
}

// Function to calculate change values
function calculateChangeValues(bmdValues, institutionName, parsedObservations) {
  const changeValues = [];

  bmdValues.forEach(bmd => {
    const site = bmd.SITE;
    let region = null;
    let precisionLow = false;
    let scanModeChanged = false;

    if (site.includes("LUMBAR SPINE")) {
      region = "Lumbar Spine"
      precisionLow = parsedObservations.ap_spine_precission_low || false;
      scanModeChanged = parsedObservations.apSpineScanModeChange || false;
    } else if (site.includes("LEFT TOTAL PROXIMAL FEMUR")) {
      region = "Left Total Proximal Femur"
      precisionLow = parsedObservations.left_femur_precission_low || false;
      scanModeChanged = parsedObservations.leftFemurScanModeChange || false;
    } else if (site.includes("RIGHT TOTAL PROXIMAL FEMUR")) {
      region = "Right Total Proximal Femur"
      precisionLow = parsedObservations.right_femur_precission_low || false;
      scanModeChanged = parsedObservations.rightFemurScanModeChange || false;
    } else if (site.includes("1/3 LEFT RADIUS")) {
      region = "1/3 Left Radius"
      precisionLow = parsedObservations.left_forearm_precission_low || false;
      scanModeChanged = parsedObservations.leftForearmScanModeChange || false;
    } else if (site.includes("1/3 RIGHT RADIUS")) {
      region = "1/3 Right Radius"
      precisionLow = parsedObservations.right_forearm_precission_low || false;
      scanModeChanged = parsedObservations.rightForearmScanModeChange || false;
    } else if (site.includes("TOTAL BODY")) {
      region = "Total Body"
      precisionLow = parsedObservations.total_body_precission_low || false;
      scanModeChanged = parsedObservations.totalBodyScanModeChange || false;
    }

    const changeVsPrevious = bmd.CHANGE_VS_PREVIOUS;

    if (region && changeVsPrevious !== null) {
      const changeInfo = getChangeType(changeVsPrevious, region, institutionName);
      changeInfo.precisionLow = precisionLow;
      changeInfo.scanModeChanged = scanModeChanged;
      changeValues.push(changeInfo);
    }
  });

  return changeValues;
}

// Function to get change type (simplified version)
function getChangeType(value, region, institutionName) {
  // Define LSC values for different regions
  const lscValues = {
    "Left Total Proximal Femur": 0.033,
    "Right Total Proximal Femur": 0.033,
    "Lumbar Spine": 0.017,
    "1/3 Left Radius": 0.016,
    "1/3 Right Radius": 0.016,
    "Total Body": 0.015
  };

  // Get the LSC value for the given region, default to 0.1 if not found
  const lscValue = lscValues[region] || 0.1;
  const significant = Math.abs(value) > lscValue;
  const changeDirection = value > 0 ? "increase" : "decrease";

  return {
    region,
    change: significant ? changeDirection : null,
    significant
  };
}

// Function to get change statement
function getChangeStatement(changeValues) {
  let changeStatement = "";
  let negativeChange = false;

  changeValues.forEach(changeValue => {
    if (changeStatement !== "") {
      changeStatement += "\n"
    }
    changeStatement += `${changeValue['region']}: `
    if (changeValue.precisionLow) {
      changeStatement += `The area within the region of interest COULD NOT be duplicated within 2%. Current results CANNOT be accurately compared with previous.`;
    }
    else if (changeValue.scanModeChanged) {
      changeStatement += `Scan mode HAS CHANGED between scans. Current results CANNOT be accurately compared with previous.`;
    }
    else if (changeValue.significant) {
      changeStatement += `Statistically SIGNIFICANT interval ${changeValue.change.toUpperCase()}.`;
      if (changeValue.change === "decrease") {
        negativeChange = true;
      }
    } else {
      changeStatement += `NO statistically significant interval change.`;
    }
  });

  if (changeStatement.length > 0) {
    changeStatement += "\n"
    changeStatement += negativeChange ? "Current management could be reassessed." : "Current management remains appropriate.";
  }

  return changeStatement.trim();
}

// Function to get follow-up statement
function getFollowUpStatement(fractureRiskCategory, diagnosticCategory, patientAge, studyType) {
  let followUpStatement = null;

  const diagnosticFollowUp = {
    "Below Expected Range for Age": "Follow-up suggested in 1-3 years.",
    "Within Expected Range for Age": "Follow-up suggested in 3 years.",
    "Osteoporosis": "Follow-up suggested in 1-2 years.",
    "Low Bone Mass": "Follow-up suggested in 1-3 years.",
    "Normal": "Follow-up suggested in 5 years.",
  };

  const riskFollowUp = {
    "Low, less than 10%": "Follow-up suggested in 5 years.",
    "Moderate, 10-20%": "Follow-up suggested in 1-3 years.",
    "High, greater than 20%": "Follow-up suggested in 1-2 years.",
    "Undefined": null,
  };

  if (patientAge < 50) {
    followUpStatement = diagnosticFollowUp[diagnosticCategory] || null;
  } else {
    followUpStatement = riskFollowUp[fractureRiskCategory];
    if (followUpStatement === null) {
      followUpStatement = diagnosticFollowUp[diagnosticCategory];
    }
  }

  if (followUpStatement === "Follow-up suggested in 5 years." && studyType === 'baseline') {
    followUpStatement = "Follow-up suggested in 3 years.";
  }

  return followUpStatement;
}

// Function to get a random month name
function getRandomMonth() {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return months[Math.floor(Math.random() * months.length)];
}

// Function to get a height change statement
function getHeightChangeStatement(parsedObservations) {
  if (parsedObservations.is_height_change_gt_2cm) {
    return (
      "There has been a > 2cm prospective height loss as compared to previous. " +
      "Plain film correlation of the thoracolumbar spine is advised to assess for a compression fracture."
    );
  }
  return null;
}

const Demo = ({basicMode=false, initStudyType='baseline'}) => {
    // eslint-disable-next-line
  const [showReportOnly, setShowReportOnly] = useState(basicMode);
  const [studyType, setStudyType] = useState(initStudyType);
  const [numberOfStudies, setNumberOfStudies] = useState(1);
  const [patientAge, setPatientAge] = useState('75');
  const [patientGender, setPatientGender] = useState('female');
  const [fractureRisk, setFractureRisk] = useState('Undefined');
  const [riskModifierStatement, setRiskModifierStatement] = useState('None');
  const [findings, setFindings] = useState('');
  const [diagnosticCategory, setDiagnosticCategory] = useState('');
  const [summaryOverallStatement, setSummaryOverallStatement] = useState('');
  const [summaryRecommendations, setSummaryRecommendations] = useState('');
  const [summaryChangeStatement, setSummaryChangeStatement] = useState('');
  const [summaryFollowUpStatement, setSummaryFollowUpStatement] = useState('');

  // New state variables for risk factors
  const [fragilityFractureHistory, setFragilityFractureHistory] = useState(false);
  const [glucocorticoidHistory, setGlucocorticoidHistory] = useState(false);
  const [fragilityHipFracture, setFragilityHipFracture] = useState(false);
  const [fragilityVertebralFracture, setFragilityVertebralFracture] = useState(false);
  const [twoOrMoreFragilityFractures, setTwoOrMoreFragilityFractures] = useState(false);

  const [diagnosticExplanation, setDiagnosticExplanation] = useState([]);
  const [riskExplanation, setRiskExplanation] = useState([]);

  const [cannotBeCompared, setCannotBeCompared] = useState(false);
  const [boneActiveDrugTherapy, setBoneActiveDrugTherapy] = useState(false);

  const [weightChange, setWeightChange] = useState(false);
  const [differentMachine, setDifferentMachine] = useState(false);

  // New state variables for exam items
  const [includeLumbarSpine, setIncludeLumbarSpine] = useState(true);
  const [includeLeftHip, setIncludeLeftHip] = useState(true);
  const [includeRightHip, setIncludeRightHip] = useState(false);
  const [includeLeftForearm, setIncludeLeftForearm] = useState(false);
  const [includeRightForearm, setIncludeRightForearm] = useState(false);
  const [includeTotalBody, setIncludeTotalBody] = useState(false);
  const [heightChange, setHeightChange] = useState(false);

  // New state variables for precision and scan mode change
  const [lumbarSpinePrecision, setLumbarSpinePrecision] = useState(true);
  const [lumbarSpineScanModeChange, setLumbarSpineScanModeChange] = useState(false);
  const [leftHipPrecision, setLeftHipPrecision] = useState(true);
  const [leftHipScanModeChange, setLeftHipScanModeChange] = useState(false);
  const [rightHipPrecision, setRightHipPrecision] = useState(true);
  const [rightHipScanModeChange, setRightHipScanModeChange] = useState(false);
  const [leftForearmPrecision, setLeftForearmPrecision] = useState(true);
  const [leftForearmScanModeChange, setLeftForearmScanModeChange] = useState(false);
  const [rightForearmPrecision, setRightForearmPrecision] = useState(true);
  const [rightForearmScanModeChange, setRightForearmScanModeChange] = useState(false);
  const [totalBodyPrecision, setTotalBodyPrecision] = useState(true);
  const [totalBodyScanModeChange, setTotalBodyScanModeChange] = useState(false);

  useEffect(() => {
    setCannotBeCompared(weightChange || differentMachine);
  }, [weightChange, differentMachine]);

  useEffect(() => {
    generateRandomFindings()
    // eslint-disable-next-line
  }, [includeLumbarSpine, includeLeftHip, includeRightHip, includeLeftForearm, includeRightForearm, includeTotalBody])

  function determineRiskCategory(tScore, lowRiskThreshold, highRiskThreshold, fragilityFractureHistory, glucocorticoidHistory, lumbarScore, explanation) {
  let riskModifierStatement = "";
  let fractureRisk = null;

  if (tScore > lowRiskThreshold) {
    explanation.push(`T-score ${tScore} is above the low-risk threshold ${lowRiskThreshold.toFixed(2)}. LOW Fracture Risk.`);
    if (fragilityFractureHistory) {
      riskModifierStatement = "Fracture risk has been modified to MEDIUM by the history of prior fragility fracture.";
      fractureRisk = "Moderate, 10-20%";
      explanation.push(riskModifierStatement);
    } else if (glucocorticoidHistory) {
      riskModifierStatement = "Fracture risk has been modified to MEDIUM by glucocorticoid history.";
      fractureRisk = "Moderate, 10-20%";
      explanation.push(riskModifierStatement);
    } else if (lumbarScore && lumbarScore <= -2.5) {
      riskModifierStatement = "Fracture risk has been modified to MEDIUM by the lumbar spine T-score.";
      fractureRisk = "Moderate, 10-20%";
      explanation.push(riskModifierStatement);
    } else {
      fractureRisk = "Low, less than 10%";
      explanation.push("Fracture risk remains LOW.");
    }
  } else if (lowRiskThreshold >= tScore && tScore > highRiskThreshold) {
    explanation.push(`T-score ${tScore} is between the low and high-risk thresholds (${lowRiskThreshold.toFixed(2)}, ${highRiskThreshold.toFixed(2)}). MEDIUM Fracture Risk.`);
    if (fragilityFractureHistory) {
      riskModifierStatement = "Fracture risk has been modified to HIGH by the history of prior fragility fracture.";
      fractureRisk = "High, greater than 20%";
      explanation.push(riskModifierStatement);
    } else if (glucocorticoidHistory) {
      riskModifierStatement = "Fracture risk has been modified to HIGH by glucocorticoid history.";
      fractureRisk = "High, greater than 20%";
      explanation.push(riskModifierStatement);
    } else {
      fractureRisk = "Moderate, 10-20%";
      explanation.push("Fracture risk is MODERATE.");
    }
  } else {
    explanation.push(`T-score ${tScore} is below the high-risk threshold ${highRiskThreshold.toFixed(2)}. HIGH Fracture Risk.`);
    fractureRisk = "High, greater than 20%";
  }

  return [fractureRisk, riskModifierStatement];
}

  function calculateRisk() {
    const explanation = []

    const age = parseInt(patientAge, 10);
    const sex = patientGender;
    const bmdValues = findings;

    if (age < 50) {
      setFractureRisk("Fracture risk cannot be stated in patients less than 50 years of age.");
      explanation.push(`Fracture risk cannot be stated for patients under 50 years of age. Patient age: ${age}.`);
      setRiskExplanation(explanation);
      return;
    }
    // Check for high-risk conditions first
    if (fragilityFractureHistory && glucocorticoidHistory) {
      setFractureRisk("High, greater than 20%");
      setRiskModifierStatement("Fracture risk is HIGH due to the history of prior fragility fracture and glucocorticoid history.");
      explanation.push(riskModifierStatement);
      setRiskExplanation(explanation);
      return;
    } else if (fragilityHipFracture) {
      setFractureRisk("High, greater than 20%");
      setRiskModifierStatement("Fracture risk is HIGH due to the history of prior fragility hip fracture.");
      explanation.push(riskModifierStatement);
      setRiskExplanation(explanation);
      return;
    } else if (fragilityVertebralFracture) {
      setFractureRisk("High, greater than 20%");
      setRiskModifierStatement("Fracture risk is HIGH due to the history of prior fragility vertebral fracture.");
      explanation.push(riskModifierStatement);
      setRiskExplanation(explanation);
      return;
    } else if (twoOrMoreFragilityFractures) {
      setFractureRisk("High, greater than 20%");
      setRiskModifierStatement("Fracture risk is HIGH due to the history of two or more fragility fractures.");
      explanation.push(riskModifierStatement);
      setRiskExplanation(explanation);
      return;
    }

    // Select the appropriate CAROC table based on sex
    const carocTable = getCarocTable(sex);

    // Get the most relevant T-score based on the patient gender and BMD values
    const tScore = getRelevantTScore(bmdValues, sex);

    // Get the spine score
    const lumbarScore = getLumbarScore(bmdValues);

    if (tScore === null) {
      if (lumbarScore === null) {
        setFractureRisk("Undefined");
        setRiskExplanation(explanation);
        return;
      } else {
        if (lumbarScore <= -2.5) {
          setFractureRisk("Moderate, 10-20%");
          setRiskModifierStatement("Fracture risk has been determined by the lumbar spine T-score as the femoral neck T-score is not available.");
          setRiskExplanation(explanation);
          return;
        } else {
          setFractureRisk("Undefined");
          setRiskExplanation(explanation);
          return;
        }
      }
    }

    // Interpolate risk thresholds based on age
    const [lowRiskThreshold, highRiskThreshold] = getRiskThresholds(carocTable, age, explanation);

    // Determine the risk category
    const [risk, riskStatement] = determineRiskCategory(
      tScore,
      lowRiskThreshold,
      highRiskThreshold,
      fragilityFractureHistory,
      glucocorticoidHistory,
      lumbarScore,
      explanation
    );
    setFractureRisk(risk);
    setRiskModifierStatement(riskStatement || 'None');
    setRiskExplanation(explanation);
  }

  const handleStudyTypeChange = (event) => {
    const newStudyType = event.target.value;
    setStudyType(newStudyType);
    if (newStudyType === 'baseline') {
      setNumberOfStudies(1); // Set to 1 by default if baseline
    } else {
      // Assign a random number of previous studies between 2 and 3+
      setNumberOfStudies(Math.random() < 0.5 ? 2 : 3);
    }
  };

  const handlePatientAgeChange = (event) => {
    setPatientAge(event.target.value);
  };

  const handlePatientGenderChange = (event) => {
    setPatientGender(event.target.value);
  };

  const getReferenceExaminationText = () => {
    if (studyType === 'baseline') {
      return 'None';
    }

    let baselineDate = `${getRandomMonth()} ${Math.floor(Math.random() * 28) + 1}, ${2016 + Math.floor(Math.random() * 10)}`;
    let prevDate = `${getRandomMonth()} ${Math.floor(Math.random() * 28) + 1}, ${2016 + Math.floor(Math.random() * 10)}`;
    if (new Date(prevDate) < new Date(baselineDate)) {
      const temp = baselineDate;
      baselineDate = prevDate;
      prevDate = temp;
    }

    switch (numberOfStudies) {
      case 1:
        return `Previous examination on ${baselineDate}.`;
      case 2:
        return `Baseline on ${baselineDate} and the previous on ${prevDate}.`;
      default:
        return `Multiple previous examinations, including a baseline on ${baselineDate} and the most recent on ${prevDate}.`;
    }
  };

  function generateRandomFindings() {
    const sites = [
      { name: "LUMBAR SPINE (L1-L4)", include: includeLumbarSpine },
      { name: "LEFT FEMORAL NECK", include: includeLeftHip },
      { name: "LEFT TOTAL PROXIMAL FEMUR", include: includeLeftHip },
      { name: "RIGHT FEMORAL NECK", include: includeRightHip },
      { name: "RIGHT TOTAL PROXIMAL FEMUR", include: includeRightHip },
      { name: "1/3 LEFT RADIUS", include: includeLeftForearm },
      { name: "1/3 RIGHT RADIUS", include: includeRightForearm },
      { name: "TOTAL BODY", include: includeTotalBody }
    ];

    const randomFindings = sites.map(site => {
      if (site.include) {
        const currentBMD = (Math.random() * (1.5 - 0.8) + 0.8).toFixed(3);
        const previousBMD = (studyType === 'baseline' || site.name.includes("FEMORAL NECK")) ? null : (Math.random() * (1.5 - 0.8) + 0.8).toFixed(3);
        const changeVsPrevious = previousBMD ? (currentBMD - previousBMD).toFixed(3) : null;
        const pChangeVsPrevious = previousBMD ? ((changeVsPrevious / previousBMD) * 100).toFixed(1) : null;
        const finding = {
          SITE: site.name,
          BMD: currentBMD,
          "T-score": (Math.random() * (3 - (-3)) + (-3)).toFixed(1),
          "Z-score": (Math.random() * (3 - (-3)) + (-3)).toFixed(1),
          PREVIOUS_BMD: previousBMD,
          CHANGE_VS_PREVIOUS: changeVsPrevious,
          PCHANGE_VS_PREVIOUS: pChangeVsPrevious,
        };

        if (patientGender === 'male' && (site.name.includes("FEMORAL NECK"))) {
          const femaleReferenceFinding = {
            ...finding,
            SITE: `${site.name} (FEMALE REFERENCE)`,
            "T-score": (Math.random() * (2 - (-2)) + (-2)).toFixed(1),
            "Z-score": (Math.random() * (2 - (-2)) + (-2)).toFixed(1),
            PREVIOUS_BMD: null,
            CHANGE_VS_PREVIOUS: null,
            PCHANGE_VS_PREVIOUS: null,
          };
          return [finding, femaleReferenceFinding];
        }

        return finding;
      }
      return null;
    }).flat().filter(Boolean);

    setFindings(randomFindings);
  }

  const formatFindingsText = (findings) => {
    const results = [];
    const parsedAge = parseInt(patientAge, 10);
    const scoreType = parsedAge >= 50 ? "T-score" : "Z-score";

    findings.forEach(value => {
      if (!value || !value.BMD) {
        return; // Skip entries with no BMD data
      }

      let bmdText = `${value.SITE} = ${value.BMD} g/cm². ${scoreType} = ${value[scoreType]}`;

      if (value.PREVIOUS_BMD !== null) {
        let changeText = `This value has ${value.CHANGE_VS_PREVIOUS > 0 ? 'INCREASED' : 'DECREASED'} by ${Math.abs(value.CHANGE_VS_PREVIOUS)} g/cm²`;
        if (value.PCHANGE_VS_PREVIOUS !== null) {
          changeText += ` (${value.PCHANGE_VS_PREVIOUS}%)`;
        }
        changeText += " compared to the previous.";
        bmdText += ` ${changeText}`;
      }

      results.push(bmdText);
    });

    return results.join("\n\n");
  };

  useEffect(() => {
    generateRandomFindings()
    // eslint-disable-next-line
  }, [studyType, patientAge, patientGender])

  useEffect(() => {
    if (findings.length > 0) {
      const diagnosticExplanationArray = [];
      const category = getDiagnosticCategory(parseInt(patientAge, 10), findings, diagnosticExplanationArray);
      setDiagnosticCategory(category);
      setDiagnosticExplanation(diagnosticExplanationArray);
    }
  }, [findings, patientAge]);

  useEffect(() => {
    if (findings.length > 0) {
      calculateRisk();
    }
    // eslint-disable-next-line
  }, [findings, patientAge, patientGender, fragilityFractureHistory, glucocorticoidHistory, fragilityHipFracture, fragilityVertebralFracture, twoOrMoreFragilityFractures]);

  // Function to update summary components and text
  const updateSummary = () => {
    const explanation = [];
    const parsedObservations = {
      cannot_be_compared: cannotBeCompared,
      bone_active_drug_therapy: boneActiveDrugTherapy,
      is_weight_change_gt_10_percent: weightChange,
      different_exam_location: differentMachine,
      is_height_change_gt_2cm: heightChange,
      ap_spine_precission_low: !lumbarSpinePrecision,
      apSpineScanModeChange: lumbarSpineScanModeChange,
      left_femur_precission_low: !leftHipPrecision,
      leftFemurScanModeChange: leftHipScanModeChange,
      right_femur_precission_low: !rightHipPrecision,
      rightFemurScanModeChange: rightHipScanModeChange,
      left_forearm_precission_low: !leftForearmPrecision,
      leftForearmScanModeChange: leftForearmScanModeChange,
      right_forearm_precission_low: !rightForearmPrecision,
      rightForearmScanModeChange: rightForearmScanModeChange,
      total_body_precission_low: !totalBodyPrecision,
      totalBodyScanModeChange: totalBodyScanModeChange
    };

    const components = returnSummaryComponents(
      diagnosticCategory,
      fractureRisk,
      findings,
      'Institution Name', // Replace with actual institution name
      parsedObservations,
      explanation,
      parseInt(patientAge, 10),
      studyType
    );

    // Set individual state variables instead of a single summaryComponents object
    setSummaryOverallStatement(components.overallStatement || '');
    setSummaryRecommendations(components.recommendations || '');
    setSummaryChangeStatement(components.changeStatement || '');
    setSummaryFollowUpStatement(components.followUpStatement || '');

    // Append height change statement if applicable
    const heightChangeStatement = getHeightChangeStatement(parsedObservations);
    if (heightChangeStatement) {
      setSummaryChangeStatement(prev => prev !== '' ? `${prev}\n${heightChangeStatement}` : heightChangeStatement);
    }
  };

  // Call updateSummary whenever relevant state changes
  useEffect(() => {
    if (diagnosticCategory && fractureRisk && findings.length > 0) {
      updateSummary();
    }
    // eslint-disable-next-line
  }, [
    diagnosticCategory,
    fractureRisk,
    findings,
    patientAge,
    boneActiveDrugTherapy,
    cannotBeCompared,
    heightChange,
    weightChange,
    differentMachine,
    lumbarSpinePrecision,
    lumbarSpineScanModeChange,
    leftHipPrecision,
    leftHipScanModeChange,
    rightHipPrecision,
    rightHipScanModeChange,
    leftForearmPrecision,
    leftForearmScanModeChange,
    rightForearmPrecision,
    rightForearmScanModeChange,
    totalBodyPrecision,
    totalBodyScanModeChange
  ]);

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <div style={styles.container}>
          <Grid container spacing={2}>
            {!showReportOnly && (
              <Grid item xs={12} md={3}>
                <div style={styles.controls}>
                  <h2 style={styles.header}>Tech Worksheet</h2>
                  {/* Patient Info Section */}
                  <div style={styles.section}>
                    <h3 style={styles.subHeader}>Patient Info</h3>
                    <label style={styles.label}>
                      Patient Age:
                      <input
                        type="text"
                        value={patientAge}
                        onChange={handlePatientAgeChange}
                        style={styles.input}
                      />
                    </label>
                    <label style={styles.label}>
                      Patient Gender:
                      <select
                        value={patientGender}
                        onChange={handlePatientGenderChange}
                        style={styles.select}
                      >
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                    </label>
                  </div>
                  {/* Exam Details Section */}
                  <div style={styles.sectionSide}>
                    <h3 style={styles.subHeader}>Exam Details</h3>
                    <label style={styles.label}>
                      Study Type:
                      <select
                        value={studyType}
                        onChange={handleStudyTypeChange}
                        style={styles.select}
                      >
                        <option value="baseline">Baseline</option>
                        <option value="followup">Follow Up</option>
                      </select>
                    </label>
                    {studyType === 'followup' && (
                      <>
                        <label style={styles.label}>
                          Weight Change More Than 10%:
                          <input
                            type="checkbox"
                            checked={weightChange}
                            onChange={(e) => setWeightChange(e.target.checked)}
                          />
                        </label>
                        <label style={styles.label}>
                          Height Change More Than 2cm:
                          <input
                            type="checkbox"
                            checked={heightChange}
                            onChange={(e) => setHeightChange(e.target.checked)}
                          />
                        </label>
                        <label style={styles.label}>
                          Different Machine:
                          <input
                            type="checkbox"
                            checked={differentMachine}
                            onChange={(e) => setDifferentMachine(e.target.checked)}
                          />
                        </label>
                      </>
                    )}
                  </div>
                  {/* Exam Items Section */}
                  <div style={styles.sectionSide}>
                    <h3 style={styles.subHeader}>Exam Items</h3>
                    <label style={styles.label}>
                      Lumbar Spine:
                      <input
                        type="checkbox"
                        checked={includeLumbarSpine}
                        onChange={(e) => setIncludeLumbarSpine(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Left Hip:
                      <input
                        type="checkbox"
                        checked={includeLeftHip}
                        onChange={(e) => setIncludeLeftHip(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Right Hip:
                      <input
                        type="checkbox"
                        checked={includeRightHip}
                        onChange={(e) => setIncludeRightHip(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Left Forearm:
                      <input
                        type="checkbox"
                        checked={includeLeftForearm}
                        onChange={(e) => setIncludeLeftForearm(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Right Forearm:
                      <input
                        type="checkbox"
                        checked={includeRightForearm}
                        onChange={(e) => setIncludeRightForearm(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Total Body:
                      <input
                        type="checkbox"
                        checked={includeTotalBody}
                        onChange={(e) => setIncludeTotalBody(e.target.checked)}
                      />
                    </label>
                    <button onClick={generateRandomFindings} style={styles.button}>
                      Generate
                    </button>
                  </div>

                  {/* Fracture History Section */}
                  <div style={styles.sectionSide}>
                    <h3 style={styles.subHeader}>Fracture History</h3>
                    <label style={styles.label}>
                      Fragility Fracture History:
                      <input
                        type="checkbox"
                        checked={fragilityFractureHistory}
                        onChange={(e) => setFragilityFractureHistory(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Fragility Hip Fracture:
                      <input
                        type="checkbox"
                        checked={fragilityHipFracture}
                        onChange={(e) => setFragilityHipFracture(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Fragility Vertebral Fracture:
                      <input
                        type="checkbox"
                        checked={fragilityVertebralFracture}
                        onChange={(e) => setFragilityVertebralFracture(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Two or More Fragility Fractures:
                      <input
                        type="checkbox"
                        checked={twoOrMoreFragilityFractures}
                        onChange={(e) => setTwoOrMoreFragilityFractures(e.target.checked)}
                      />
                    </label>
                  </div>

                  {/* Medication Section */}
                  <div style={styles.sectionSide}>
                    <h3 style={styles.subHeader}>Medication</h3>
                    <label style={styles.label}>
                      Glucocorticoid History:
                      <input
                        type="checkbox"
                        checked={glucocorticoidHistory}
                        onChange={(e) => setGlucocorticoidHistory(e.target.checked)}
                      />
                    </label>
                    <label style={styles.label}>
                      Bone Active Drug Therapy:
                      <input
                        type="checkbox"
                        checked={boneActiveDrugTherapy}
                        onChange={(e) => setBoneActiveDrugTherapy(e.target.checked)}
                      />
                    </label>
                  </div>

                  {/* Precision Section */}
                  {studyType !== 'baseline' && (
                    <div style={styles.sectionSide}>
                      <h3 style={styles.subHeader}>Precision and Scan Mode</h3>
                      {includeLumbarSpine && (
                        <div style={styles.sectionSide}>
                          <h4 style={styles.subHeader}>Lumbar Spine</h4>
                          <label style={styles.label}>
                            Precision Met:
                            <input
                              type="checkbox"
                              checked={lumbarSpinePrecision}
                              onChange={(e) => setLumbarSpinePrecision(e.target.checked)}
                            />
                          </label>
                          <label style={styles.label}>
                            Scan Mode Changed:
                            <input
                              type="checkbox"
                              checked={lumbarSpineScanModeChange}
                              onChange={(e) => setLumbarSpineScanModeChange(e.target.checked)}
                            />
                          </label>
                        </div>
                      )}
                      {includeLeftHip && (
                        <div style={styles.sectionSide}>
                          <h4 style={styles.subHeader}>Left Hip</h4>
                          <label style={styles.label}>
                            Precision Met:
                            <input
                              type="checkbox"
                              checked={leftHipPrecision}
                              onChange={(e) => setLeftHipPrecision(e.target.checked)}
                            />
                          </label>
                          <label style={styles.label}>
                            Scan Mode Changed:
                            <input
                              type="checkbox"
                              checked={leftHipScanModeChange}
                              onChange={(e) => setLeftHipScanModeChange(e.target.checked)}
                            />
                          </label>
                        </div>
                      )}
                      {includeRightHip && (
                        <div style={styles.sectionSide}>
                          <h4 style={styles.subHeader}>Right Hip</h4>
                          <label style={styles.label}>
                            Precision Met:
                            <input
                              type="checkbox"
                              checked={rightHipPrecision}
                              onChange={(e) => setRightHipPrecision(e.target.checked)}
                            />
                          </label>
                          <label style={styles.label}>
                            Scan Mode Changed:
                            <input
                              type="checkbox"
                              checked={rightHipScanModeChange}
                              onChange={(e) => setRightHipScanModeChange(e.target.checked)}
                            />
                          </label>
                        </div>
                      )}
                      {includeLeftForearm && (
                        <div style={styles.sectionSide}>
                          <h4 style={styles.subHeader}>Left Forearm</h4>
                          <label style={styles.label}>
                            Precision Met:
                            <input
                              type="checkbox"
                              checked={leftForearmPrecision}
                              onChange={(e) => setLeftForearmPrecision(e.target.checked)}
                            />
                          </label>
                          <label style={styles.label}>
                            Scan Mode Changed:
                            <input
                              type="checkbox"
                              checked={leftForearmScanModeChange}
                              onChange={(e) => setLeftForearmScanModeChange(e.target.checked)}
                            />
                          </label>
                        </div>
                      )}
                      {includeRightForearm && (
                        <div style={styles.sectionSide}>
                          <h4 style={styles.subHeader}>Right Forearm</h4>
                          <label style={styles.label}>
                            Precision Met:
                            <input
                              type="checkbox"
                              checked={rightForearmPrecision}
                              onChange={(e) => setRightForearmPrecision(e.target.checked)}
                            />
                          </label>
                          <label style={styles.label}>
                            Scan Mode Changed:
                            <input
                              type="checkbox"
                              checked={rightForearmScanModeChange}
                              onChange={(e) => setRightForearmScanModeChange(e.target.checked)}
                            />
                          </label>
                        </div>
                      )}
                      {includeTotalBody && (
                        <div style={styles.sectionSide}>
                          <h4 style={styles.subHeader}>Total Body</h4>
                          <label style={styles.label}>
                            Precision Met:
                            <input
                              type="checkbox"
                              checked={totalBodyPrecision}
                              onChange={(e) => setTotalBodyPrecision(e.target.checked)}
                            />
                          </label>
                          <label style={styles.label}>
                            Scan Mode Changed:
                            <input
                              type="checkbox"
                              checked={totalBodyScanModeChange}
                              onChange={(e) => setTotalBodyScanModeChange(e.target.checked)}
                            />
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={showReportOnly ? 12 : 6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <div style={styles.report}>
                  {!showReportOnly && <h2 style={styles.header}>Report</h2>}
                  <div style={styles.sectionMain}>
                    <div style={styles.section}>
                      <h3 style={styles.subHeader}>REFERENCE EXAMINATIONS:</h3>
                      <p style={styles.text}>{getReferenceExaminationText()}</p>
                    </div>
                    <div style={styles.section}>
                      <h3 style={styles.subHeader}>TECHNIQUE:</h3>
                      <p style={styles.text}>
                        {studyType === 'baseline'
                          ? `A baseline bone density was obtained on this ${patientAge}-year-old ${patientGender}.`
                          : `A repeat bone density was obtained on this ${patientAge}-year-old ${patientGender}.`}
                      </p>
                    </div>
                    <div style={styles.section}>
                      <h3 style={styles.subHeader}>FINDINGS:</h3>
                      {findings && patientAge && (
                        <p style={styles.text}>{formatFindingsText(findings, patientAge)}</p>
                      )}
                      <p style={styles.text}>
                        BONE MINERAL DENSITY: {diagnosticCategory}
                      </p>
                      <p style={styles.text}>10 YEAR ABSOLUTE FRACTURE RISK: {fractureRisk}</p>
                      <p style={styles.text}>RISK MODIFIERS: {riskModifierStatement}</p>
                    </div>
                    <div style={styles.section}>
                      <h3 style={styles.subHeader}>SUMMARY:</h3>
                      <p style={styles.text}>{summaryOverallStatement}</p>
                      <p style={styles.text}>{summaryChangeStatement}</p>
                      <p style={styles.text}>{summaryFollowUpStatement}</p>
                      <p style={styles.text}>{summaryRecommendations}</p>
                      <p style={styles.text}>
                        CAROC recommendations (2010) age 50 years and older:<br/>
                        T-score between -1 and -2.5 = low bone mass<br/>
                        T-score -2.5 or less = osteoporosis
                      </p>
                      <p style={styles.text}>Fragility fractures of spine or hip or 2 fragility fractures elsewhere = osteoporosis and high fracture risk regardless of T-score</p>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            {!showReportOnly && (
              <Grid item xs={12} md={3}>
                <div style={styles.explanationPane}>
                  <h2 style={styles.header}>Explanation</h2>
                  <h3 style={styles.subHeader}>Diagnostic Category Explanation</h3>
                  {diagnosticExplanation.length > 0 ? (
                    diagnosticExplanation.map((exp, index) => (
                      <p key={index} style={styles.text}>{exp}</p>
                    ))
                  ) : (
                    <p style={styles.text}>No explanation available.</p>
                  )}
                  <h3 style={styles.subHeader}>Fracture Risk Explanation</h3>
                  {riskExplanation.length > 0 ? (
                    riskExplanation.map((exp, index) => (
                      <p key={index} style={styles.text}>{exp}</p>
                    ))
                  ) : (
                    <p style={styles.text}>No explanation available.</p>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
  },
  controls: {
    flex: 1,
    padding: '20px',
    backgroundColor: '#e9ecef',
    borderRadius: '8px',
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  report: {
    flex: 2,
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  sectionMain: {
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
  },
  subHeader: {
    textAlign: 'left',
    color: '#0056b3',
    marginBottom: '10px',
  },
  text: {
    whiteSpace: 'pre-line',
    color: '#333',
  },
  textBlock: {
    marginTop: '10px',
    color: '#333',
  },
  list: {
    margin: 0,
    paddingLeft: '20px',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  explanationPane: {
    flex: 1,
    padding: '20px',
    backgroundColor: '#f1f1f1',
    borderRadius: '8px',
    marginLeft: '20px',
  },
  sectionSide: {
    display: 'flex',
    flexDirection: 'column'
  }
};

export default Demo;