import React from 'react';

const BlueWrapper = ({ children, isMobile }) => (
  <div style={{
    backgroundColor: '#0A3D62',
    flexDirection: isMobile ? 'column' : 'row',
    display: 'flex',
    justifyContent: 'center',
  }}>
    <div style={{
        maxWidth: '1340px', 
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        padding: '50px 20px',
        gap: '20px',
    }}>
        {children}
    </div>

  </div>
);

export default BlueWrapper;