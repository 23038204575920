import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Demo from './Demo';
import './App.css';
import GlucocorticoidStatus from './GlucocorticoidStatus';
import HeightWeightChange from './HeightWeightChange';
import PrecisionTestingAndScanMode from './PrecisionTestingAndScanMode';
import NextStudyPrecisionCard from './NextStudyPrecissionCard';
import FractureHistory from './FractureHistory';
import PatientHistoryRetentionCard from './PatientHistoryRetentionCard';
import Benefit from './Benefit';
import NotesIcon from '@mui/icons-material/Notes';
import Button from '@mui/material/Button';
import { Grid, Typography, Box } from '@mui/material';
import { Assignment, Person, Dvr, FileCopy, Send } from '@mui/icons-material';
import CenteredContainer from './CenteredContainer';
import BlueWrapper from './BlueWrapper';
import { FAQ } from './FAQ';
import { Footer } from './Footer';
import { Section } from './Section'

function App({isMobile, theme}) {
  return (
    <ThemeProvider theme={theme}>
      <div style={{
        fontFamily: 'Open Sans, Arial, sans-serif',
        lineHeight: '1.6',
        margin: '0 auto',
      }}>

        {/* Hero Section */}
        <section id="clinics" style={{
          textAlign: 'left',
          backgroundColor: '#E8F6F3',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          background: 'linear-gradient(rgba(10, 61, 98, 0.6), rgba(10, 61, 98, 0.6)), url("/hero.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'right top',
          color: '#F5F5F5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}>
          <div style={{ 
            maxWidth: '550px', 
            background: 'rgba(10, 61, 98, 0.7)', 
            height: '100%', 
            padding: isMobile ? '50px 10px' : '100px 20px' 
          }}>
            <h1 style={{ fontFamily: 'Roboto, Arial, sans-serif', fontSize: isMobile ? '4rem' : '5rem', color: 'white', margin: 0 }}>Introducing <span style={{fontWeight: 'bold'}}>DXA Encode</span></h1>
            <p style={{ fontSize: isMobile ? '1.1rem' : '1.25rem' }}>
              Developed by C-Encode, DXA Encode boosts clinic capacity by reducing technologist documentation time and enabling faster BMD reporting for radiologists.
            </p>
          </div>
        </section>

      <CenteredContainer>
        {/* How It Works Section */}
        <section id="how-it-works" style={{marginTop: 20, marginBottom: 20}}>
          <Box>
            <Typography variant="h4" sx={{ color: '#0A3D62', marginBottom: '20px', textAlign: 'center' }}>How It Works</Typography>
            <Grid container justifyContent="center" rowSpacing={5}>

              {/* Step 1: Input Patient Data */}
              <Grid item xs={12} sm={6} md={2} sx={{ textAlign: 'center', paddingLeft: '0!important', margin: '0px 10px' }}>
                <Assignment sx={{ fontSize: 50, color: '#0A3D62', mb: 1 }} />
                <Typography variant="h6" minHeight={!isMobile ? 75 : 0}>Seamless Data Entry</Typography>
                <Typography variant="body2">
                  Technologists input patient history, such as fracture details and medications, just once. DXA Encode securely retains this data for future exams.
                </Typography>
              </Grid>

              {/* Step 2: Automated Data Capture */}
              <Grid item xs={12} sm={6} md={2} sx={{ textAlign: 'center', paddingLeft: '0!important', margin: '0px 10px' }}>
                <Person sx={{ fontSize: 50, color: '#0A3D62', mb: 1 }} />
                <Typography variant="h6" minHeight={!isMobile ? 75 : 0}>Data Prepopulation</Typography>
                <Typography variant="body2">
                  Key data, including the current and previous measurements are automatically pulled from the DXA machine, minimizing manual entry.
                </Typography>
              </Grid>

              {/* Step 3: Precision & Compliance Checks */}
              <Grid item xs={12} sm={6} md={2} sx={{ textAlign: 'center', paddingLeft: '0!important', margin: '0px 10px' }}>
                <Dvr sx={{ fontSize: 50, color: '#0A3D62', mb: 1 }} />
                <Typography variant="h6" minHeight={!isMobile ? 75 : 0}>Precision & Compliance Checks</Typography>
                <Typography variant="body2">
                  The system calculate height and weight changes, ensures scan precission targets are met and flags changes in scan modes
                </Typography>
              </Grid>

              {/* Step 4: Auto-Generated Report */}
              <Grid item xs={12} sm={6} md={2} sx={{ textAlign: 'center', paddingLeft: '0!important', margin: '0px 10px' }}>
                <FileCopy sx={{ fontSize: 50, color: '#0A3D62', mb: 1 }} />
                <Typography variant="h6" minHeight={!isMobile ? 75 : 0}>Auto-Generated Report</Typography>
                <Typography variant="body2">
                  DXA Encode produces a complete, CAR-compliant report with BMD values, diagnostic category, CAROC fracture risk, and follow-up recommendations.
                </Typography>
              </Grid>

              {/* Step 5: Seamless PACS Integration */}
              <Grid item xs={12} sm={6} md={2} sx={{ textAlign: 'center', paddingLeft: '0!important', margin: '0px 10px' }}>
                <Send sx={{ fontSize: 50, color: '#0A3D62', mb: 1 }} />
                <Typography variant="h6" minHeight={!isMobile ? 75 : 0}>PACS / RIS / Dictation Software Integration</Typography>
                <Typography variant="body2">
                  The finalized worksheet is automatically sent to PACS, and the preliminary report is sent to RIS / dictation software making it instantly available for radiologist review and sign-off.
                </Typography>
              </Grid>

            </Grid>
          </Box>
        </section>
      </CenteredContainer>

        <BlueWrapper isMobile={isMobile}>
          <>
          <Section title="For Clinics" icon="🏥" isMobile={isMobile}>
            <ul>
              <li style={{ marginBottom: '20px' }}><strong>Minimized Data Entry: </strong> DXA Encode pulls data directly from the DXA machine, eliminating manual data entry and history searches.</li>
              <li style={{ marginBottom: '20px' }}><strong>One-Time History Entry:</strong> Patient history (e.g., fractures, medications) is securely saved for easy access in future exams.</li>
              <li style={{ marginBottom: '20px' }}><strong>Automated Calculations:</strong> Automatically tracks height, weight, precision values, and scan mode changes, reducing errors.</li>
              <li style={{ marginBottom: '20px' }}><strong>Seamless PACS Integration:</strong> Worksheets go straight to PACS, eliminating scanning steps.</li>
            </ul>
          </Section>

          <Section title="For Radiologists" icon="📑" isMobile={isMobile}>
            <ul>
              <li style={{ marginBottom: '20px' }}><strong>Auto-Generated, CAR-Compliant Reports:</strong> DXA Encode generates reports with BMD values, change calculation, diagnostic category, CAROC fracture risk, and follow-up recommendations.</li>
              <li style={{ marginBottom: '20px' }}><strong>Consistent Data:</strong> Each report includes risk modifiers from the tech worksheet, custom LSCs, and area precision details, reducing the chance of inconsistencies.</li>
              <li style={{ marginBottom: '20px' }}><strong>Streamlined Workflow:</strong> Reports are accessible in dictation software / RIS, allowing quick review and sign-off in the existing workflow.</li>
            </ul>
          </Section>
          </>
        </BlueWrapper>

        <CenteredContainer>
            <section style={{ padding: '20px', backgroundColor: '#1D1D1D', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '15px' }}>
                  <NotesIcon style={{ color: '#F5F5F5', fontSize: '50px' }} />
                </div>
                <div>
                  <h3 style={{ color: '#F5F5F5', margin: 0, fontSize: '1.5rem' }}>Want an interactive way to explore our tool?</h3>
                  <p style={{ color: '#C0C0C0', margin: 0, fontSize: '1rem' }}>Experience DXA Encode with our interactive demo!</p>
                </div>
              </div>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => window.open('/demo', '_blank')}
              >
                Try Now
              </Button>
            </section>
        </CenteredContainer>

        {/* Interactive Components Section */}
        <CenteredContainer>
          <section style={{ marginBottom: '20px' }}>
            <Benefit
              title="Efficient Patient History Retention"
              description="During the patient intake process, DXA Encode allows technologists or clerical staff to input patient history details, such as fracture history and medications, just once. The system securely retains this information, making it readily available for future exams. When a patient returns, DXA Encode automatically recalls their past records, allowing technologists to focus solely on updating new measurements rather than re-entering historical data. This feature significantly reduces data entry time, ensuring a smoother workflow and consistent patient records over time."
              component={PatientHistoryRetentionCard}
            />

            <Benefit
              title="Automated Height & Weight Change Detection"
              description="The Height and Weight Tracking component of DXA Encode automatically compares the patient’s current measurements with their previous records, flagging significant changes—such as weight changes over 10% or height reductions exceeding 2 cm. Previously, technologists would need to calculate these differences manually using Excel or a calculator, which was time-consuming and prone to errors. With DXA Encode, this entire process is automated and pre-populated, saving valuable time and ensuring accurate, instant identification of physical changes relevant for fracture risk analysis."
              component={HeightWeightChange}
            />

            <Benefit
              title="Glucocorticoid Use Assessment Simplification"
              description="DXA Encode enables technologists to quickly and accurately assess if the patient has met the CAR critical threshold for glucocorticoid use—specifically, whether they have taken an equivalent of 7.5 mg of prednisone for at least 90 days within the past 365 days. The tool simplifies this process by automatically converting different glucocorticoid medications into prednisone equivalents, ensuring accurate and standardized assessment. This automation not only speeds up the evaluation but also supports compliance with CAR guidelines, providing radiologists with a reliable assessment of glucocorticoid impact on bone health."
              component={GlucocorticoidStatus}
            />

            <Benefit
              title="Streamlined Fracture History Documentation"
              description="DXA Encode provides an interactive Fracture History component where users can enter details of any fractures the patient has had, including location, cause (e.g., fragility), and age at the time of the fracture. Once this information is entered, the system retains it for future visits, so technologists don’t need to re-enter it, streamlining the workflow significantly."
              component={FractureHistory}
            />

            <Benefit
              title="Pre-Set Precision Ranges for Accurate Scans"
              description="DXA Encode offers pre-set precision ranges for each scan area, guiding technologists on target measurement thresholds before scanning begins. By automating the 2% variance check, DXA Encode saves time, reduces manual effort, and minimizes errors, making it easier for technologists to meet precise scanning requirements every time."
              component={NextStudyPrecisionCard}
            />

            <Benefit
              title="Automated Precision Testing and Scan Mode Tracking"
              description="DXA Encode automatically calculates precision for each scanned body region (AP Spine, Left/Right Femur, Left/Right Forearm, and Total Body) using its Area, flagging any deviations over a 2% threshold. It also tracks changes in scan mode (e.g., Standard, Thin) from previous exams, providing essential context for radiologists. Previously, these precision checks were done manually with calculators or spreadsheets, which was time-consuming and prone to errors. DXA Encode automates the process, saving time, reducing errors, and ensuring consistent bone density tracking."
              component={PrecisionTestingAndScanMode}
            />

            <Benefit
              title="Seamless PACS Integration for Worksheets"
              description="Once all data is reviewed, the completed worksheet is automatically sent to the PACS as a secondary capture image, where it is available for radiologists to access and review. This seamless integration with PACS eliminates the need for manual data entry, ensuring that radiologists receive a fully compliant report with consistent and accurate information."
              component={() => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img 
                    src="/worksheet.jpg" 
                    alt="Worksheet" 
                    style={{ 
                      maxWidth: '100%', 
                      height: 'auto', 
                      marginLeft: isMobile ? '0' : '20px' 
                    }} 
                  />
                </div>
              )}
            />

            <Benefit
              title="Ready-to-Review BMD Reports In RIS / Dictation Software"
              description="Once the technologist submits the completed worksheet, DXA Encode automatically generates a fully compliant BMD report based on CAR (Canadian Association of Radiologists) standards. This report includes diagnostic categories, fracture risk assessments, and follow-up recommendations, making it ready for the radiologist to review and sign off with minimal additional input. This automation not only saves time but also ensures consistency and regulatory compliance across all reports."
              component={() => (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                  <div style={{ flex: '1', marginRight: '10px' }}>
                    <Demo basicMode={true} initStudyType={'followup'}/>
                  </div>
                </div>
              )}
            />
          </section>
        </CenteredContainer>

        {/* Call to Action Section */}
        <BlueWrapper>
          <section id="contact" style={{ padding: '40px', backgroundColor: '#F5F5F5', textAlign: 'center', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
            <h2 style={{ color: '#0A3D62', fontFamily: 'Roboto, Arial, sans-serif', fontSize: '1.75rem' }}>Ready to Increase Your Clinic’s DXA Capacity?</h2>
            <p>Contact us to learn more or get a demo:</p>
            <a href="mailto:info@c-encode.com" style={{ display: 'inline-block', padding: '15px 30px', backgroundColor: '#FF6B35', color: '#F5F5F5', textDecoration: 'none', borderRadius: '8px', fontWeight: 'bold', fontSize: '1rem' }}>
              info@c-encode.com
            </a>
          </section>
        </BlueWrapper>
        <FAQ />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;