import React, {useState} from 'react'
import CenteredContainer from './CenteredContainer';

export const FAQ = () => {
    const [openFAQ, setOpenFAQ] = useState(null);
    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    const faqs = [
        { question: "What is DXA Encode?", answer: "DXA Encode is a tool designed to streamline DXA scan documentation by automatically capturing key patient and scan data from DXA machines, saving time for technologists and providing consistent, compliant reports for radiologists." },
        { question: "What do I need to get started with DXA Encode?", answer: "DXA Encode is a web-based tool deployed on your premises, designed to integrate seamlessly with your existing workflow. Technologists can use the same computer they currently use to interact with the DXA modality. We can install DXA Encode on a computer provisioned by your IT/IS team or bring a dedicated computer on site and connect it for immediate use." },
        { question: "Is DXA Encode easy for technologists to use?", answer: "DXA Encode is designed with technologists in mind, automating data capture, precision calculations, and history tracking. The user-friendly interface reduces manual work, allowing technologists to focus on patient care." },
        { question: "How does DXA Encode handle historical patient data?", answer: "Once patient data is entered, DXA Encode securely retains it for future exams. This allows technologists to focus on new measurements, saving time and ensuring historical data accuracy across visits." },
        { question: "How does DXA Encode integrate with PACS and HIS systems?", answer: "DXA Encode is fully compatible with any PACS and HIS system through HL7 and DICOM standards, allowing for seamless integration with your existing infrastructure. This compatibility ensures data is consistently accessible across systems and allows radiologists to review reports and images efficiently." },
        { question: "How is patient information stored in DXA Encode?", answer: "DXA Encode uses HL7 FHIR (Fast Healthcare Interoperability Resources) for data storage, ensuring a secure, structured, and standardized format for all patient records. This format makes data interoperable with other healthcare systems, ensuring secure and consistent data access across systems." },
        { question: "Can historical data be migrated to DXA Encode?", answer: "Yes, historical data can be migrated from your current DXA modality into DXA Encode. This migration process brings in past patient data, making it readily accessible for ongoing patient care and enabling comprehensive record continuity." },
        { question: "Can DXA Encode be used for multiple DXA machine models?", answer: "DXA Encode is compatible with a range of DXA machines and can capture relevant data seamlessly. Contact us to confirm specific machine compatibility." },
        { question: "Does DXA Encode support multiple sites?", answer: "DXA Encode can be configured to support multiple sites, allowing consistent data capture and reporting across all locations." },
        { question: "Can DXA Encode be customized for my clinic’s workflow?", answer: "Yes, DXA Encode is flexible and can be customized to align with your clinic's specific workflow and reporting standards. Contact us to discuss your requirements." },
        { question: "What compliance standards does DXA Encode follow?", answer: "DXA Encode follows CAR (Canadian Association of Radiologists) guidelines, ensuring all reports are consistent, compliant, and ready for radiologist review upon completion." },
        { question: "What are the benefits of DXA Encode for radiologists?", answer: "DXA Encode provides radiologists with consistent, high-quality reports and comprehensive patient histories, reducing review time and ensuring reports meet compliance standards." },
        { question: "How does DXA Encode ensure data security?", answer: "DXA Encode is designed with security and privacy as top priorities. The system can be deployed locally to ensure that patient data remains under your control and is compliant with PIPEDA, PHIPA, and other privacy regulations." },
        {
        question: "What is the origin story of DXA Encode?",
        answer: (
            <>
            <p>
                DXA Encode began as an open-source project at Trillium Health Partners’ Institute for Better Health, initially focused on automating the prepopulation of Bone Mineral Density (BMD) values and diagnostic categories directly into reports. This tool was <a href="https://github.com/orgs/ibh-daai" target="_blank" rel="noopener noreferrer">open-sourced</a> to share its benefits more widely. Sites requiring a full solution including technologist documentation, support, and integration use the DXA Encode commercial offering.
            </p>
            </>
        )
        }
    ];

    return (
    <CenteredContainer>
    <section id="faq" style={{ padding: '30px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
        <h2 style={{ color: '#0A3D62', fontFamily: 'Roboto, Arial, sans-serif', fontSize: '1.75rem', textAlign: 'center' }}>Frequently Asked Questions</h2>
        <div style={{ marginTop: '20px' }}>
        {faqs.map((faq, index) => (
            <div key={index} style={{ marginBottom: '10px', borderBottom: '1px solid #B0BEC5', paddingBottom: '10px' }}>
            <h3 onClick={() => toggleFAQ(index)} style={{ fontSize: '1.25rem', color: '#0A3D62', cursor: 'pointer' }}>
                {faq.question}
            </h3>
            {openFAQ === index && (
                <p style={{ marginTop: '5px', fontSize: '1rem', color: '#333', paddingLeft: '20px' }}>{faq.answer}</p>
            )}
            </div>
        ))}
        </div>
    </section>
    </CenteredContainer>

    )
}