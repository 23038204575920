import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header } from './Headers';
import App from './App';
import Demo from './Demo';
import { FAQ } from './FAQ';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

function Home() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Router>
      <div>
        <Header isMobile={isMobile} />
        <Routes>
          <Route path="/" element={<App isMobile={isMobile} theme={theme}/>} />
          <Route path="/demo" element={<Demo isMobile={isMobile} theme={theme}/>} />
          <Route path="/faq" element={<FAQ isMobile={isMobile} theme={theme}/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default Home;