import React, { useState } from 'react';

const GlucocorticoidStatus = () => {
  const [selectedDrug, setSelectedDrug] = useState("hydrocortisone");
  const [dose, setDose] = useState("35");
  const [duration, setDuration] = useState("5");

  // Equivalent doses based on the provided chart
  const drugEquivalents = {
    hydrocortisone: 20,
    prednisone: 5,
    prednisolone: 5,
    methylprednisolone: 4,
    triamcinolone: 4,
    betamethasone: 0.75,
    dexamethasone: 0.75,
    deflazacort: 6.5 // average 6-7.5 mg
  };

  const glucocorticoidOptions = Object.keys(drugEquivalents);

  // Determine status based on selectedDrug, dose, and duration
  const equivalentDose = drugEquivalents[selectedDrug.toLowerCase()] / 5 || 0;
  const convertedDose = dose / equivalentDose;
  const status = convertedDose >= 7.5 && parseInt(duration) >= 3 ? "Positive" : "Negative";

  return (
    <div style={styles.container}>
      {/* Glucocorticoid Status Box */}
      <div style={styles.statusBox}>
        <h3 style={styles.statusHeader}>Glucocorticoid Status</h3>
        <p style={{ ...styles.statusText, color: status === "Positive" ? "#FF6B35" : "#0A3D62" }}>
          {status}
        </p>
      </div>

    {/* Display Equivalent Dose Calculation */}
    {selectedDrug && dose &&
        <div>
        <p style={styles.equivalenceInfo}>
            Equivalent dose of prednisone: {convertedDose.toFixed(2)} mg
        </p>
        </div>
    }

    <hr style={styles.divider} />
      {/* Taking Drug Question */}
      <div style={styles.form}>
        <h4 style={styles.formHeader}>Are you taking any glucocorticoid medications?</h4>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            value="yes"
            checked={!!selectedDrug}
            onChange={() => setSelectedDrug(glucocorticoidOptions[0])} // Default to first option
            style={styles.radio}
          />
          Yes
        </label>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            value="no"
            checked={!selectedDrug}
            onChange={() => {
              setSelectedDrug("");
              setDose("");
              setDuration("");
            }}
            style={styles.radio}
          />
          No
        </label>
      </div>

      {/* Drug Selection Form */}
      {!!selectedDrug && (
        <div style={styles.form}>
          <h4 style={styles.formHeader}>Select Glucocorticoid Drug</h4>
          <label style={styles.label}>Drug:
            <select value={selectedDrug} onChange={(e) => setSelectedDrug(e.target.value)} style={styles.select}>
              <option value="" disabled>Select a drug</option>
              {glucocorticoidOptions.map((drug, index) => (
                <option key={index} value={drug}>{drug.charAt(0).toUpperCase() + drug.slice(1)}</option>
              ))}
            </select>
          </label>

          <label style={styles.label}>Dose (mg):
            <input
              type="number"
              value={dose}
              onChange={(e) => setDose(e.target.value)}
              style={styles.input}
              placeholder="Enter dose"
              min="0"
            />
          </label>

          <label style={styles.label}>Duration (months):
            <input
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              style={styles.input}
              placeholder="Enter months"
              min="0"
            />
          </label>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Open Sans, Arial, sans-serif',
    margin: '10px',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    backgroundColor: 'white',
  },
  statusBox: {
    textAlign: 'center',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  statusHeader: {
    fontSize: '1.5rem',
    color: '#0A3D62',
    margin: 0,
  },
  statusText: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  formHeader: {
    fontSize: '1.25rem',
    color: '#0A3D62',
    marginBottom: '10px',
  },
  label: {
    fontSize: '1rem',
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
  },
  select: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #B0BEC5',
    marginTop: '5px',
  },
  input: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #B0BEC5',
    marginTop: '5px',
  },
  equivalenceInfo: {
    fontSize: '0.9rem',
    color: '#0A3D62',
    backgroundColor: '#F5F5F5',
    padding: '10px',
    borderRadius: '4px',
    textAlign: 'center',
    marginTop: '10px',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    color: '#333',
  },
  radio: {
    marginRight: '5px',
  },
  definition: {
    fontSize: '0.8rem',
    color: '#6C757D',
    backgroundColor: '#F8F9FA',
    padding: '8px',
    borderRadius: '4px',
    textAlign: 'center',
    marginTop: '10px',
  },
  divider: {
    margin: '10px 0',
    borderTop: '1px solid #E0E0E0',
  },
};

export default GlucocorticoidStatus;