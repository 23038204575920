import React, { useState } from 'react';

const FractureHistory = () => {
  const [hasFractures, setHasFractures] = useState(true);
  const [fractures, setFractures] = useState([
    {
      location: 'Vertebra',
      cause: 'Fragility (Fall from standing or sitting height)',
      age: '45',
      note: 'Fell while walking'
    }
  ]);
  const [newFracture, setNewFracture] = useState({
    location: '',
    cause: '',
    age: '',
    note: ''
  });

  // Check if any fractures qualify as fragility fractures
  const fragilityFractureLocations = ['forearm', 'vertebra', 'proximal femur', 'proximal humerus'];
  const fragilityCauses = ['Fragility (Fall from standing or sitting height)'];
  const isFragilityFracture = fractures.some(
    fracture =>
      fragilityFractureLocations.includes(fracture.location.toLowerCase()) &&
      fragilityCauses.includes(fracture.cause)
  );
  const fragilityStatus = isFragilityFracture ? "Positive" : "Negative";

  // Handle changes in new fracture form
  const handleFractureChange = (e) => {
    const { name, value } = e.target;
    setNewFracture(prevState => ({ ...prevState, [name]: value }));
  };

  // Add new fracture to the list
  const addFracture = () => {
    setFractures([...fractures, newFracture]);
    setNewFracture({ location: '', cause: '', age: '', note: '' });
  };

  // Remove fracture from the list
  const removeFracture = (index) => {
    setFractures(fractures.filter((_, i) => i !== index));
  };

  return (
    <div style={styles.container}>
      {/* Fragility Fracture Status Box */}
      <div style={styles.statusBox}>
        <h3 style={styles.statusHeader}>Fragility Fracture History</h3>
        <p style={{ ...styles.statusText, color: fragilityStatus === "Positive" ? "#FF6B35" : "#0A3D62" }}>
          {fragilityStatus}
        </p>
      </div>

      {/* Fracture History Question */}
      <div style={styles.form}>
        <h4 style={styles.formHeader}>Have you broken any bones after age of 40?</h4>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            value="yes"
            checked={hasFractures}
            onChange={() => setHasFractures(true)}
            style={styles.radio}
          />
          Yes
        </label>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            value="no"
            checked={!hasFractures}
            onChange={() => {
              setHasFractures(false);
              setFractures([]);
            }}
            style={styles.radio}
          />
          No
        </label>
      </div>
     <hr style={styles.divider} />
      {/* Fracture Details Form */}
      {hasFractures && (
        <div style={styles.fractureDetails}>
          <div style={styles.tableHeader}>
            <div style={styles.tableCell}>Location</div>
            <div style={styles.tableCell}>Cause</div>
            <div style={styles.tableCell}>Age</div>
            <div style={styles.tableCell}>Note</div>
            <div style={styles.tableCell}>Action</div>
          </div>

          {fractures.map((fracture, index) => (
            <div key={index} style={styles.tableRow}>
              <div style={styles.tableCell}>{fracture.location}</div>
              <div style={styles.tableCell}>{fracture.cause}</div>
              <div style={styles.tableCell}>{fracture.age}</div>
              <div style={styles.tableCell}>{fracture.note}</div>
              <div style={styles.tableCell}>
                <button onClick={() => removeFracture(index)} style={styles.removeButton}>Remove</button>
              </div>
            </div>
          ))}

          {/* Input fields for new fracture */}
          <div style={styles.tableRow}>
            <div style={styles.tableCell}>
              <select
                name="location"
                value={newFracture.location}
                onChange={handleFractureChange}
                style={styles.select}
              >
                <option value="">Select Location</option>
                <option value="Forearm">Forearm</option>
                <option value="Vertebra">Vertebra</option>
                <option value="Proximal Femur">Proximal Femur</option>
                <option value="Proximal Humerus">Proximal Humerus</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div style={styles.tableCell}>
              <select
                name="cause"
                value={newFracture.cause}
                onChange={handleFractureChange}
                style={styles.select}
              >
                <option value="">Select Cause</option>
                <option value="Fragility (Fall from standing or sitting height)">
                  Fragility (Fall from standing or sitting height)
                </option>
                <option value="High Impact">High Impact</option>
              </select>
            </div>
            <div style={styles.tableCell}>
              <input
                type="number"
                name="age"
                value={newFracture.age}
                onChange={handleFractureChange}
                style={styles.input}
                placeholder="Age"
                min="0"
              />
            </div>
            <div style={styles.tableCell}>
              <input
                type="text"
                name="note"
                value={newFracture.note}
                onChange={handleFractureChange}
                style={styles.input}
                placeholder="Note"
              />
            </div>
            <div style={styles.tableCell}>
              <button onClick={addFracture} style={styles.addButton}>Add Fracture</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Open Sans, Arial, sans-serif',
    maxWidth: '600px',
    margin: '10px auto',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    backgroundColor: 'white',
  },
  statusBox: {
    textAlign: 'center',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  statusHeader: {
    fontSize: '1.5rem',
    color: '#0A3D62',
    margin: 0,
  },
  statusText: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  formHeader: {
    fontSize: '1.25rem',
    color: '#0A3D62',
    marginBottom: '10px',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    color: '#333',
  },
  radio: {
    marginRight: '5px',
  },
  fractureDetails: {
    marginTop: '20px',
  },
  tableHeader: {
    display: 'flex',
    backgroundColor: '#f5f5f5',
    padding: '10px',
    borderRadius: '4px',
    fontWeight: 'bold',
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
    boxSizing: 'border-box',
  },
  tableCell: {
    flex: 1,
    padding: '5px',
    boxSizing: 'border-box',
    minWidth: '0',
  },
  select: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #B0BEC5',
    marginTop: '5px',
    width: '100%',
    boxSizing: 'border-box',
  },
  input: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #B0BEC5',
    marginTop: '5px',
    width: '100%',
    boxSizing: 'border-box',
  },
  addButton: {
    padding: '8px 12px',
    backgroundColor: '#0A3D62',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  removeButton: {
    padding: '8px 12px',
    backgroundColor: '#FF6B35',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  divider: {
    margin: '10px 0',
    borderTop: '1px solid #E0E0E0',
  },
};

export default FractureHistory;