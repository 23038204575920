import React from 'react';

const NextStudyPrecisionCard = () => {
  // Sample data (replace with actual data as needed)
  const date = "December 1, 2020";
  const time = "01:58 PM";
  const riskLevel = "BD HIGH RISK";
  const accession = "1915199576002324";
  const institution = "Demo Hospital";
  const precisionRanges = {
    AP_Spine: { min: 49.36, max: 51.36 },
    Left_Femur: { min: 29.92, max: 31.12 },
  };

  return (
    <div style={styles.card}>
      <div style={styles.header}>
        <h3 style={styles.date}>{date} at {time}</h3>
        <p style={styles.riskLevel}>{riskLevel}</p>
      </div>
      <p style={styles.info}>Accession: {accession}</p>
      <p style={styles.info}>Institution: {institution}</p>
      <div style={styles.precisionSection}>
        <h4 style={styles.precisionHeader}>Precision Ranges for Next Study (2%)</h4>
        <p style={styles.precisionText}>
          AP Spine: {precisionRanges.AP_Spine.min} - {precisionRanges.AP_Spine.max}
        </p>
        <p style={styles.precisionText}>
          Left Femur: {precisionRanges.Left_Femur.min} - {precisionRanges.Left_Femur.max}
        </p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '400px',
    margin: '20px auto',
    fontFamily: 'Open Sans, Arial, sans-serif',
    lineHeight: '1.6',
    border: '1px solid #E0E0E0',
    backgroundColor: 'white',
  },
  header: {
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: '10px',
    marginBottom: '10px',
  },
  date: {
    fontSize: '1.2rem',
    color: '#0A3D62',
    margin: 0,
  },
  riskLevel: {
    fontSize: '1rem',
    color: '#0A3D62',
    fontWeight: 'bold',
    marginTop: '5px',
  },
  info: {
    fontSize: '0.9rem',
    color: '#333',
    margin: '5px 0',
  },
  precisionSection: {
    marginTop: '15px',
  },
  precisionHeader: {
    fontSize: '1rem',
    color: '#FF6B35',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  precisionText: {
    fontSize: '0.9rem',
    color: '#333',
    margin: '3px 0',
  },
};

export default NextStudyPrecisionCard;