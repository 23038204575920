import React from 'react';

const PatientHistoryRetentionCard = () => {
  const lastVisit = {
    date: 'October 31, 2024 at 02:51 PM',
    technologist: 'FA',
    details: {
      fractureHistory: 'Recorded',
      medications: 'Prednisone',
      height: '160 cm',
      weight: '70 kg',
    },
  };

  return (
    <div style={styles.cardContainer}>
      <div style={styles.card}>
        <p style={styles.date}>{lastVisit.date}</p>
        <p style={styles.technologist}>Technologist: {lastVisit.technologist}</p>
        <hr style={styles.divider} />
        <div style={styles.details}>
          <p><strong>Fracture History:</strong> {lastVisit.details.fractureHistory}</p>
          <p><strong>Medications:</strong> {lastVisit.details.medications}</p>
          <p><strong>Height:</strong> {lastVisit.details.height}</p>
          <p><strong>Weight:</strong> {lastVisit.details.weight}</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
    marginBottom: '20px',
    fontFamily: 'Open Sans, Arial, sans-serif',
  },
  title: {
    color: '#0A3D62',
    fontSize: '1.5rem',
    marginBottom: '10px',
  },
  card: {
    padding: '20px',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    backgroundColor: '#FFF',
  },
  date: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#0A3D62',
  },
  technologist: {
    color: '#666',
    fontSize: '1rem',
  },
  divider: {
    margin: '10px 0',
    borderTop: '1px solid #E0E0E0',
  },
  details: {
    fontSize: '1rem',
    color: '#333',
  },
};

export default PatientHistoryRetentionCard;