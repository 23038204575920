import React, { useState } from 'react';

const HeightWeightChange = () => {
  const [currentHeight, setCurrentHeight] = useState("175");
  const [previousHeight, setPreviousHeight] = useState("178");
  const [currentWeight, setCurrentWeight] = useState("85.6");
  const [previousWeight, setPreviousWeight] = useState("80.5");

  // Calculate height loss
  const heightLoss = (previousHeight - currentHeight).toFixed(1);
  const isHeightLossSignificant = heightLoss > 2;

  // Check if weight difference exceeds 10%
  const weightChangePercent = previousWeight
    ? ((Math.abs(currentWeight - previousWeight) / previousWeight) * 100).toFixed(1)
    : 0;
  const isWeightChangeSignificant = weightChangePercent > 10;

  return (
    <div style={styles.container}>
      <div style={styles.statusBox}>
        <h3 style={styles.statusHeader}>Height & Weight Change</h3>
        <p style={{ ...styles.statusText, ...(isHeightLossSignificant ? styles.significantChange : styles.nonSignificantChange) }}>
          {isHeightLossSignificant ? (
            <>
              Significant height loss detected ({heightLoss} cm)
            </>
          ) : (
            <>
              Height change is not significant ({Math.abs(heightLoss)} cm)
            </>
          )}
        </p>
        <p style={{ ...styles.statusText, ...(isWeightChangeSignificant ? styles.significantChange : styles.nonSignificantChange) }}>
          {isWeightChangeSignificant ? (
            <>
              Significant weight change detected ({weightChangePercent} %)
            </>
          ) : (
            <>
              Weight change is not significant ({weightChangePercent} %)
            </>
          )}
        </p>
      </div>
    <hr style={styles.divider} />
      {/* Input Form for Height and Weight */}
      <div style={styles.form}>
        <div style={styles.inputGroup}>
          <div style={styles.inputWrapper}>
            <label style={styles.label}>
              Current Height (cm):
              <input
                type="number"
                value={currentHeight}
                onChange={(e) => setCurrentHeight(e.target.value)}
                style={styles.input}
                placeholder="Enter current height"
              />
            </label>
          </div>
          <div style={styles.inputWrapper}>
            <label style={styles.label}>
              Previous Height (cm):
              <input
                type="number"
                value={previousHeight}
                onChange={(e) => setPreviousHeight(e.target.value)}
                style={styles.input}
                placeholder="Enter previous height"
                disabled={true}
              />
            </label>
          </div>
        </div>
        <div style={styles.inputGroup}>
          <div style={styles.inputWrapper}>
            <label style={styles.label}>
              Current Weight (kg):
              <input
                type="number"
                value={currentWeight}
                onChange={(e) => setCurrentWeight(e.target.value)}
                style={styles.input}
                placeholder="Enter current weight"
              />
            </label>
          </div>
          <div style={styles.inputWrapper}>
            <label style={styles.label}>
              Previous Weight (kg):
              <input
                type="number"
                value={previousWeight}
                onChange={(e) => setPreviousWeight(e.target.value)}
                style={styles.input}
                placeholder="Enter previous weight"
                disabled={true}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Open Sans, Arial, sans-serif',
    margin: '0 auto',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    backgroundColor: 'white',
  },
  statusBox: {
    textAlign: 'center',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  statusHeader: {
    fontSize: '1.5rem',
    color: '#0A3D62',
    margin: 0,
  },
  statusText: {
    fontSize: '1rem',    
    fontWeight: 'bold',
    color: '#333',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  formHeader: {
    fontSize: '1.25rem',
    color: '#0A3D62',
    marginBottom: '10px',
  },
  label: {
    fontSize: '1rem',
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #B0BEC5',
    marginTop: '5px',
  },
  inputGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  inputWrapper: {
    flex: 1,
  },
  significantChange: {
    color: '#FF9800',
  },
  nonSignificantChange: {
    color: '#333',
  },
  icon: {
    marginRight: '8px',
  },
    divider: {
    margin: '10px 0',
    borderTop: '1px solid #E0E0E0',
  },
};

export default HeightWeightChange;