import React from 'react';

const CenteredContainer = ({ children }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '10px 0px'
  }}>
    <div style={{
      maxWidth: '1340px',
      padding: '20px',
      width: '100%',
    }}>
      {children}
    </div>
  </div>
);

export default CenteredContainer;
