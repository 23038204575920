import React from 'react';
import { ExpandableBox } from './ExpandableBox';
import { Box, Typography, Paper } from '@mui/material';

const Benefit = ({ title, description, component: Component }) => (
  <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, backgroundColor: '#F9FAFB', marginBottom: 3 }}>
    <ExpandableBox title={title}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'start' 
      }}>
        <Typography variant="h6" sx={{ flex: 1, marginRight: { sm: 2 }, marginBottom: { xs: 2, sm: 0 } }}>
          {description}
        </Typography>
        <Box sx={{ flex: 1, marginLeft: { sm: 2 } }}>
          <Component />
        </Box>
      </Box>
    </ExpandableBox>
  </Paper>
);

export default Benefit;