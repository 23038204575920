import React, { useState } from 'react';
import './PrecisionHistory.css'; // Ensure the path is correct

const PrecisionTestingAndScanMode = () => {
  const [currentPrecision, setCurrentPrecision] = useState("52.05");
  const [previousPrecision, setPreviousPrecision] = useState("50.36");
  const [scanMode, setScanMode] = useState("Standard"); // Default scan mode
  const previousScanMode = "Standard";

  // Calculate percentage change and check if it exceeds 2%
  const precisionChangePercent = previousPrecision
    ? (((currentPrecision - previousPrecision) / previousPrecision) * 100).toFixed(2)
    : 0;
  const isPrecisionChangeSignificant = Math.abs(precisionChangePercent) > 2;

  // Check if scan mode has changed
  const isScanModeChanged = scanMode !== previousScanMode;

  return (
    <div className="container">
      <h2 className="sectionHeader">AP Spine L1-L4</h2>
      <hr className="divider" />
      <h3 className="subHeader">PRECISION TESTING</h3>
      <div className="row">
        <div className="field">
          <label className="label">Current Area (cm²)</label>
          <input
            type="number"
            value={currentPrecision}
            onChange={(e) => setCurrentPrecision(parseFloat(e.target.value))}
            className="input"
            placeholder="Enter current value"
          />
        </div>
        <div className="field">
          <label className="label">Previous Area (cm²)</label>
          <input
            type="number"
            value={previousPrecision}
            onChange={(e) => setPreviousPrecision(parseFloat(e.target.value))}
            className="input"
            disabled
          />
        </div>
        <div className="field">
          <label className="label">Precision Met ({precisionChangePercent}%)</label>
          <input
            type="text"
            value={isPrecisionChangeSignificant ? "No" : "Yes"}
            className={`input ${isPrecisionChangeSignificant ? 'highlight' : ''}`}
            disabled
          />
        </div>
      </div>
      <h3 className="subHeader">SCAN MODE</h3>
      <div className="row">
        <div className="field">
          <label className="label">Current Scan Mode</label>
          <div className="radioGroup">
            {["Standard", "Thick", "Thin"].map((mode) => (
              <label key={mode} className="radioLabel">
                <input
                  type="radio"
                  value={mode}
                  checked={scanMode === mode}
                  onChange={(e) => setScanMode(e.target.value)}
                  className="radio"
                />
                {mode}
              </label>
            ))}
          </div>
        </div>
        <div className="field">
          <label className="label">Previous Scan Mode</label>
          <input
            type="text"
            value={previousScanMode}
            className="input"
            disabled
          />
        </div>
        <div className="field">
          <label className="label">Changed From Last</label>
          <input
            type="text"
            value={isScanModeChanged ? "Yes" : "No"}
            className={`input ${isScanModeChanged ? 'highlight' : ''}`}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default PrecisionTestingAndScanMode;